import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

// Performance Monitoring (Optional, Remove if Not Needed)
import ErrorBoundary from './components/ErrorBoundary';
// import reportWebVitals from './reportWebVitals';
import LoadingSpinner from './components/LoadingSpinner';

// reportWebVitals(console.log);

// Lazy Loading for Better Performance
const App = React.lazy(() => import('./App'));



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ErrorBoundary>
            {/* Suspense for Lazy Loading */}
            <Suspense fallback={<LoadingSpinner message="Please wait, loading app..." />}>
                <App />
            </Suspense>
        </ErrorBoundary>
    </React.StrictMode>
);


// import React, { Suspense } from 'react';
// import ReactDOM from 'react-dom/client';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import './index.css';

// // Import components
// import ErrorBoundary from './components/ErrorBoundary';
// import reportWebVitals from './reportWebVitals';
// import LoadingSpinner from './components/LoadingSpinner';
// import NotFoundPage from './components/NotFoundPage';  // Import a new 404 page

// reportWebVitals(console.log);

// // Lazy Loading the App component
// const App = React.lazy(() => import('./App'));

// // Create the root element
// const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(
//     <React.StrictMode>
//         <Router>
//             <ErrorBoundary>
//                 <Suspense fallback={<LoadingSpinner message="Please wait, loading app..." />}>
//                     <Routes>
//                         {/* Define Routes */}
//                         <Route path="/" element={<App />} />
//                         <Route path="*" element={<NotFoundPage />} /> {/* Catch-all for 404 */}
//                     </Routes>
//                 </Suspense>
//             </ErrorBoundary>
//         </Router>
//     </React.StrictMode>
// );
